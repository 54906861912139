<template>
    <TheMain>
        <template #main>
            <BaseHeader :title="'Default'" />
            <slot />
            <BaseFooter />
            <BaseErrorMaintenanceMode v-if="false" />
        </template>
    </TheMain>
</template>

<script setup lang="ts">
import BaseErrorMaintenanceMode from '@/components/Base/Error/BaseErrorMaintenanceMode.vue';
import BaseFooter from '@/components/Base/Footer/BaseFooter.vue';
import BaseHeader from '@/components/Base/Header/BaseHeader.vue';
import BodyContainerCenter from '@/components/Body/BodyContainerCenter.vue';
import LayoutSidebarLeft from '@/components/Layout/Sidebar/LayoutSidebarLeft.vue';
import LayoutSidebarRight from '@/components/Layout/Sidebar/LayoutSidebarRight.vue';
import LayoutSidebarRightArticle from '@/components/Layout/Sidebar/LayoutSidebarRightArticle.vue';
import TheMain from '@/components/TheMain.vue';
import { useStoreArticlesShow } from '@/stores/articles/show';
import { useStoreSystem } from '@/stores/system';

const show = useStoreArticlesShow();

// if (process.client) {
useStoreSystem().setSidebarClosed(true);
// }
</script>
